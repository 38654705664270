<template>
  <div class="container">
    <NuxtLink to="/">
      <img class="logo" alt="logo" width="202" height="38" src="~/assets/logo.svg">
    </NuxtLink>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppHeader',
});
</script>

<style scoped>
.container {
  padding: 50px 0 0 186px;
  transition: ease all 500ms;
  margin-bottom: 72px;
}

.logo {
  object-fit: contain;
}

@media screen and (max-width: 480px) {
  .container {
    margin-top: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 42px;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  .container {
    padding: 40px 0 0 20px;
    margin-bottom: 42px;
  }
}
</style>
