<template>
  <div class="cookies-container" v-show="isVisible">
    <div class="icons">
      <img src="~/assets/cookies.svg" alt="cookies">
      <img src="~/assets/close.svg" alt="close" :style="{ cursor: 'pointer' }" :onclick="accept">
    </div>
    <div class="title"></div>
    <h3>Korzystamy z plików cookies</h3>
    <small>
      Ta strona używa plików cookies w celu dostosowania zawartości do Twoich preferencji.<br>
      <a href="">Dowiedź się więcej.</a>
    </small>
    <div class="accept-button" :onclick="() => accept()">
      <h4>Akceptuję</h4>
    </div>
    <div class="reject-button" :onclick="() => reject()">
      <h4>Nie zgadzam się</h4>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    const cookiesRejected = sessionStorage.getItem('cookiesRejected');
    if (cookiesAccepted === null && cookiesRejected === null) {
      this.isVisible = true;
    }

    if (cookiesAccepted === 'true') {
      this.accept();
    }
  },
  methods: {
    accept() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.isVisible = false;
    },
    reject() {
      sessionStorage.setItem('cookiesRejected', 'true');
      this.isVisible = false;
    },
  },
});
</script>

<style scoped>
.cookies-container {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  margin: 0 68px 62px 0;
  padding: 20px;
  box-shadow: 4px 12px 60px 0px rgba(13, 31, 77, 0.12);
  z-index: 1000;
  border-radius: 8px;
  max-width: 343px;

  @media (max-width: 768px) {
    margin: 0 20px 20px 20px;
  }

  small {
    margin-top: 8px;
    color: #798491;

    a {
      text-decoration: underline;
      font-weight: 700;
      color: #11335B;
    }
  }
}

.icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.accept-button,
.reject-button {
  padding: 18px 24px;
  border-radius: 6px;
  background-color: #11335B;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;

  h4 {
    font-family: 'Sora';
  }
}

.reject-button {
  background-color: transparent;
  color: #11335B;
  padding: 0;
}
</style>
