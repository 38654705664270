import fetchContacts from "~/composables/endpoints/contacts";

const storeName = 'contactsStore';

export const useContactsStore = defineStore(storeName, {
  state: () => ({
    contacts: {
      phone: '',
      email: '',
    },
  }),
  getters: {
    getContacts(state): Contacts {
      return state.contacts;
    },
  },
  actions: {
    async init() {
      if (this.contacts.phone === '' || this.contacts.email === '') {
        await useAsyncData(async () => {
          return await Promise.all([
            fetchContacts().then((data) => {
              this.contacts = data;
            }),
          ]);
        });
      }
    },
  },
});
